<template>
  <ui-header class="header q-mt-lg" :class="[{white: isWhiteClass}]">
    <ui-link
      class="logo"
      :class="{'logo-right': direction.isRight}"
      to="/"
      aria-label="Home"
    >
      <DFLogo/>
      <div class="title">
        {{ $t(title) }}
      </div>
    </ui-link>
    <div class="menu h-menu">
      <div
        v-click-outside="
          () => {
            forWhomShowList(false);
          }
        "
        class="for-whom"
      >
        <div class="for-whom-main-button">
          <button
            :class="[
              {
                white: isWhiteClass,
                button_active: loc.includes(`/${locale}/for-`),
              },
            ]"
            @click="forWhomShowList(true)"
          >
            {{ $t('ourCustomers') }}
          </button>
        </div>
        <div v-if="forWhomShowListVar" class="for-whom-main-list">
          <ui-link to="/for-households">
            <div
              :class="{
                'for-whom-main-list-element': true,
                link_active_bg: loc === `/${locale}/for-households`,
              }"
              @click="forWhomShowList(false)"
            >
              {{ $t('households') }}
            </div>
          </ui-link>
          <ui-link to="/for-designers">
            <div
              :class="{
                'for-whom-main-list-element': true,
                link_active_bg: loc === `/${locale}/for-designers`,
              }"
              @click="forWhomShowList(false)"
            >
              {{ $t('designers') }}
            </div>
          </ui-link>
          <ui-link to="/for-developers">
            <div
              :class="{
                'for-whom-main-list-element': true,
                link_active_bg: loc === `/${locale}/for-developers`,
              }"
              @click="forWhomShowList(false)"
            >
              {{ $t('realEstDev') }}
            </div>
          </ui-link>
          <ui-link to="/for-finishers">
            <div
              :class="{
                'for-whom-main-list-element': true,
                link_active_bg: loc === `/${locale}/for-finishers`,
              }"
              @click="forWhomShowList(false)"
            >
              {{ $t('finishingComp') }}
            </div>
          </ui-link>
          <ui-link to="/for-furniture-makers">
            <div
              :class="{
                'for-whom-main-list-element': true,
                link_active_bg:
                  loc === `/${locale}/for-furniture-makers`,
              }"
              @click="forWhomShowList(false)"
            >
              {{ $t('furnitureComp') }}
            </div>
          </ui-link>
        </div>
      </div>
      <ui-link
        :class="{
          'text-w': true,
          link_active: loc === `/${locale}/gallery`,
        }"
        to="/gallery"
      >
        {{ $t('gallery') }}
      </ui-link>
      <ui-link
        :class="{
          'text-w': true,
          link_active: loc === `/${locale}/plans`,
        }"
        to="/plans"
      >
        {{ $t('plans') }}
      </ui-link>
      <ui-link
        :class="{
          'text-w': true,
          link_active: loc === `/${locale}/real-estate`,
        }"
        to="/real-estate"
      >
        {{ $t('realty') }}
      </ui-link>
      <ui-link
        :class="{
          'text-w': true,
          link_active: loc === `/${locale}/help-center`,
        }"
        to="/help-center"
      >
        {{ $t('helpCenter') }}
      </ui-link>
      <div
        v-click-outside="
          () => {
            swithchShowExhibitionsList(false);
          }
        "
        class="for-whom"
      >
        <div class="for-whom-main-button">
          <button
            :class="[{white: isWhiteClass}]"
            @click="swithchShowExhibitionsList(true)"
          >
            {{ $t('events') }}
          </button>
        </div>
        <div v-if="showExhibitionsList" class="for-whom-main-list">
          <ui-link to="/gitexMorocco">
            <div
              class="for-whom-main-list-element"
              @click="swithchShowExhibitionsList(false)"
            >
              GITEX
            </div>
          </ui-link>
          <ui-link to="/cityscape">
            <div
              class="for-whom-main-list-element"
              @click="swithchShowExhibitionsList(false)"
            >
              CITYSCAPE
            </div>
          </ui-link>
        </div>
      </div>
    </div>
    <div class="right" :class="{'right-arabic': direction.isRight}">
      <ui-fab
        v-if="listLang.length > 1"
        class="change-lang"
        :options="listLang"
        :type="isWhiteClass ? 'light' : ''"
        @select="onSelectLang"
      >
        <IconLangWhite v-if="isWhiteClass"/>
        <IconLang v-else/>
      </ui-fab>
      <div class="h-btn">
        <div class="goahead">
          <div class="goahead-item">
            <DownloadLauncherButton
              color="secondary"
              :add-version-to-label="false"
              :isGoToTariff="!!isHomePage"
              :size="ButtonSizeType.small"
            >
              <IconDownloadWhite/>
            </DownloadLauncherButton>
          </div>
        </div>
      </div>
      <div
        v-if="!isLoggedIn"
        class="login"
        @click="user.showLoginModal()"
      >
        <IconLoginWhite v-if="isWhiteClass"/>
        <IconLogin v-else/>
        {{ $t('login') }}
      </div>
      <div v-if="isLoggedIn" class="user-block">
        <ui-link to="/profile">
          <IconUser v-if="!isWhiteClass" class="h-icon-pr"/>
          <IconUserWhite v-else class="h-icon-pr"/>
          <div class="username">
            {{
              user.email.length > 6
                ? user.email.slice(0, 6) + '...'
                : user.email
            }}
          </div>
        </ui-link>
      </div>
    </div>
  </ui-header>
  <ui-header
    class="header mobile-header"
    :class="[{white: isWhiteClass}]"
  >
    <div id="sidemenu">
      <button
        v-if="changeLanguageInMobileModal === false"
        class="sidemenu__btn"
        :class="[{active: navOpen}, {white: isWhiteClass}]"
        @click="navOpen = !navOpen"
      >
        <span class="top"/>
        <span class="mid"/>
        <span class="bottom"/>
      </button>
      <transition name="translateX">
        <nav
          v-show="navOpen"
          :style="{
            right: rightDir ? '0' : 'auto',
            left: rightDir ? 'auto' : '0',
          }"
        >
          <div class="sidemenu-header-main">
            <div
              v-if="!changeLanguageInMobileModal"
              class="title-mobile-menu-block_logo"
            >
              <ui-link
                class="logo"
                to="/"
                aria-label="Home"
                @click="forWhomShowListVar = false"
              >
                <DFLogo/>
                <div class="mobile-menu-title">
                  {{ $t(title) }}
                </div>
              </ui-link>
            </div>
            <div
              v-if="changeLanguageInMobileModal"
              class="title-mobile-menu-block_lang"
              dir="ltr"
            >
              <div
                class="mobile-lang-back-button"
                @click="changeLanguageInMobileModal = false"
              >
                <IconArrowToTopGray/>
              </div>
              <div class="mobile-lang-text">
                {{ $t('choseLang') }}
              </div>
            </div>
            <div
              v-if="
                !changeLanguageInMobileModal && listLang.length > 1
              "
              class="change-language-mobile-block"
              :class="{
                'change-language-mobile-block--right': rightDir,
              }"
              @click="
                changeLanguageInMobileModal =
                  !changeLanguageInMobileModal
              "
            >
              <IconLang/>
            </div>
          </div>
          <div class="sidemenu__wrapper">
            <ul
              v-if="!changeLanguageInMobileModal"
              class="sidemenu__list"
              :class="{'right-list': rightDir}"
            >
              <li class="sidemenu__item">
                <nuxt-link
                  to="/"
                  :class="{active: loc === '/'}"
                  @click="navOpen = false"
                >
                  {{ $t('goHome') }}
                </nuxt-link>
              </li>
              <li class="sidemenu__item">
                <details class="sidemenu__item__details">
                  <summary>
                    <span id="side-menu-customers-title">{{
                        $t('ourCustomers')
                      }}</span>
                    <IconArrowToTopGray class="summary-icon-open"/>
                    <IconArrowToBottom class="summary-icon-close"/>
                  </summary>
                  <nuxt-link
                    to="/for-households"
                    :class="{active: loc.includes('/for-households')}"
                    @click="navOpen = false"
                  >
                    {{ $t('households') }}
                  </nuxt-link>
                  <nuxt-link
                    class="for-designers"
                    to="/for-designers"
                    :class="{active: loc.includes('/for-designers')}"
                    @click="navOpen = false"
                  >
                    {{ $t('designers') }}
                  </nuxt-link>
                  <nuxt-link
                    to="/for-developers"
                    :class="{active: loc.includes('/for-developers')}"
                    @click="navOpen = false"
                  >
                    {{ $t('realEstDev') }}
                  </nuxt-link>
                  <nuxt-link
                    to="/for-finishers"
                    :class="{active: loc.includes('/for-finishers')}"
                    @click="navOpen = false"
                  >
                    {{ $t('finishingComp') }}
                  </nuxt-link>
                  <nuxt-link
                    to="/for-furniture-makers"
                    :class="{
                      active: loc.includes('/for-furniture-makers'),
                    }"
                    @click="navOpen = false"
                  >
                    {{ $t('furnitureComp') }}
                  </nuxt-link>
                </details>
              </li>
              <li class="sidemenu__item">
                <nuxt-link
                  to="/gallery"
                  :class="{active: loc.includes('/gallery')}"
                  @click="navOpen = false"
                >
                  {{ $t('gallery') }}
                </nuxt-link>
              </li>
              <li class="sidemenu__item">
                <nuxt-link
                  to="/plans"
                  :class="{active: loc.includes('/plans')}"
                  @click="navOpen = false"
                >
                  {{ $t('plans') }}
                </nuxt-link>
              </li>
              <li class="sidemenu__item">
                <nuxt-link
                  :class="{
                    active: loc === `/${locale}/real-estate`,
                  }"
                  @click="navOpen = false"
                  to="/real-estate"
                >
                  {{ $t('realty') }}
                </nuxt-link>
              </li>
              <li class="sidemenu__item">
                <nuxt-link
                  to="/help-center"
                  class="help-center-link"
                  :class="{active: loc === `/${locale}/help-center`}"
                  @click="navOpen = false"
                >
                  {{ $t('helpCenter') }}
                </nuxt-link>
                <!-- <nuxt-link to="/gitexMorocco" style="color:#e31e33" @click="navOpen = false">
                  GITEX
                </nuxt-link> -->
              </li>
              <li class="sidemenu__item">
                <details class="sidemenu__item__details">
                  <summary>
                    <span id="side-menu-customers-title">{{
                        $t('events')
                      }}</span>
                    <IconArrowToTopGray class="summary-icon-open"/>
                    <IconArrowToBottom class="summary-icon-close"/>
                  </summary>
                  <nuxt-link
                    to="/gitexMorocco"
                    :class="{active: loc.includes('/gitexMorocco')}"
                    @click="navOpen = false"
                  >
                    GITEX
                  </nuxt-link>
                  <nuxt-link
                    class="for-designers"
                    to="/cityscape"
                    :class="{active: loc.includes('/cityscape')}"
                    @click="navOpen = false"
                  >
                    CITYSCAPE
                  </nuxt-link>
                </details>
              </li>
              <li class="sidemenu__item">
                <nuxt-link
                  v-if="isLoggedIn"
                  to="/profile"
                  @click="navOpen = false"
                >
                  <div class="sidemenu__item__username">
                    {{ user.email }}
                  </div>
                </nuxt-link>
                <div
                  v-if="!isLoggedIn"
                  class="sidemenu__item__button"
                  @click="
                    navOpen = false;
                    user.showLoginModal();
                  "
                >
                  <div class="login-button-in-burger-menu">
                    <IconLoginWhite/>
                    <div class="login-button-in-burger-menu__text">
                      {{ $t('login') }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul
              v-if="changeLanguageInMobileModal && !isDflat"
              class="sidemenu__list sidemenu__list__language-menu"
              :class="{'right-list': rightDir}"
            >
              <li
                v-for="lang in listLang"
                :key="lang.value"
                class="sidemenu__item"
              >
                <div
                  class="sidemenu__list__language-menu__item"
                  @click="
                    onSelectLang(lang.value);
                    navOpen = false;
                    changeLanguageInMobileModal = false;
                  "
                >
                  {{ $t(lang.label) }}
                </div>
              </li>
            </ul>
            <ul
              v-if="changeLanguageInMobileModal && isDflat"
              class="sidemenu__list sidemenu__list__language-menu"
            >
              <li class="sidemenu__item">
                <div
                  class="sidemenu__list__language-menu__item"
                  @click="
                    onSelectLang('ar');
                    navOpen = false;
                    changeLanguageInMobileModal = false;
                  "
                >
                  {{ $t('العربية') }}
                </div>
              </li>
            </ul>
            <ul
              v-if="changeLanguageInMobileModal && isDflat"
              class="sidemenu__list sidemenu__list__language-menu"
            >
              <li class="sidemenu__item">
                <div
                  class="sidemenu__list__language-menu__item"
                  @click="
                    onSelectLang('ru');
                    navOpen = false;
                    changeLanguageInMobileModal = false;
                  "
                >
                  {{ $t('Русский') }}
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </transition>
      <div
        v-if="navOpen"
        class="background-opacity"
        @click="
          navOpen = false;
          changeLanguageInMobileModal = false;
        "
      />
    </div>
    <nuxt-link
      v-if="
        loc === `/${locale}/help-center` ||
        (!loc.includes('/profile') && !loc.includes('/help-center'))
      "
      class="logo"
      to="/"
      aria-label="Home"
    >
      <DFLogo/>
      <div class="title">
        {{ $t(title) }}
      </div>
    </nuxt-link>
    <ui-link
      v-else-if="loc.includes('/profile')"
      class="logo"
      to="/profile"
    >
      <div class="title">
        {{ $t('profile') }}
      </div>
    </ui-link>
    <ui-link
      v-else-if="loc.includes('/help-center')"
      class="logo"
      to="/help-center"
    >
      <div class="title">
        {{ $t('helpCenter') }}
      </div>
    </ui-link>
    <div class="right">
      <div
        v-if="!isLoggedIn"
        class="login"
        :class="[{white: isWhiteClass}]"
        @click="user.showLoginModal()"
      >
        <IconLoginWhite v-if="isWhiteClass"/>
        <IconLogin v-else/>
        {{ $t('login') }}
      </div>
      <div v-if="isLoggedIn" class="user-block">
        <ui-link to="/profile">
          <IconUser v-if="!isWhiteClass" class="h-icon-pr"/>
          <IconUserWhite v-else class="h-icon-pr"/>
        </ui-link>
      </div>
    </div>
  </ui-header>
</template>
<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue';
import {useRoute} from 'vue-router';
import {useI18n} from 'vue-i18n';
import UiHeader from '~/components/Ui/UiHeader.vue';
import IconLang from '~/assets/svg/lang.svg';
import IconLangWhite from '~/assets/svg/lang-white.svg';
import IconDownloadWhite from '~/assets/svg/download-white.svg';
import IconLoginWhite from '~/assets/svg/login-white.svg';
import IconLogin from '~/assets/svg/login.svg';
import IconUser from '~/assets/svg/user.svg';
import IconUserWhite from '~/assets/svg/user.white.svg';
import IconArrowToTopGray from '~/assets/svg/arrowtotopgray.svg';
import IconArrowToBottom from '~/assets/svg/arrowtobottom.svg';
import UiLink from '~/components/Ui/UiLink.vue';
import DFLogo from '~/components/DFLogo.vue';
import DownloadLauncherButton from '~/components/DownloadLauncherButton.vue';
import {useRuntimeConfig} from '#imports';
import {useUserStore} from '~/modules/User/user.store';
import {useBaseStore} from '~/modules/BaseModule/base.store';
import {useDirectionStore} from '~/store/direction.store';
import {ButtonSizeType} from '~/interfaces/ui/button';
import {goTo} from '~/helpers/baseFunction';

const base = useBaseStore();
const user = useUserStore();
const router = useRoute();
const loc = computed(() => router.path);

const {locale} = useI18n();

const isHomePage = computed(() => {
  const slashes = router.fullPath.split('/').length - 1;
  return slashes === 1 && router.fullPath.length > 0;
});

const isWhiteClass = computed(
  () =>
    [
      `/${locale.value}/compilation`,
      `/${locale.value}/help-center`,
    ].includes(loc.value) ||
    (loc.value === `/${locale.value}/real-estate` &&
      Object.keys(router.query).length === 0),
);

const goto = (path) => {
  goTo(path);
}

const direction = useDirectionStore();
const rightDir = computed(() => direction.isRight);

const changeLanguageInMobileModal = ref(false);

const forWhomShowListVar = ref(false);
const showExhibitionsList = ref(false);

const runtimeConfig = useRuntimeConfig();
const title = runtimeConfig.public.vueAppTitle;
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');

const listLang = base.listLangCurrent;

const isLoggedIn = computed(() => user.isLoggedIn);

const userInfo = ref({username: ''});

const navOpen = ref(false);

function forWhomShowList(val: boolean) {
  forWhomShowListVar.value = val;
}

function swithchShowExhibitionsList(val: boolean) {
  showExhibitionsList.value = val;
}

function onSelectLang(option: string) {
  locale.value = option;
}

user.$subscribe((_, state) => {
  if (!state.error) {
    if (state.isLoggedIn) {
      user.hideLoginModal();
    }
    // user.isLoggedIn = true
    userInfo.value = state;
  }
});
watchEffect(() => {
  if (typeof document !== 'undefined') {
    if (navOpen.value) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }
});
</script>
<style lang="scss" scoped>
.ui-header {
  color: $dark;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  padding-top: $p-6;

  .menu {
    align-items: center;
    display: flex;
    gap: 16px;
    font-weight: 600;
  }

  .right-arabic {
    margin-left: 0 !important;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 16px;

    .user-block {
      .username {
        min-width: 60px;
      }
    }

    .h-lang {
      .q-icon {
        font-size: 18px;
      }
    }

    .login {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;
      //margin-left: 16px;
    }

    .lang-btn-groupe-f {
      width: 120px;
      background: var(--var-blue-100);
      border-radius: 8px;
      position: relative;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        /* Прозрачные границы */
        border-bottom: 10px solid #2491e7;
        /* Добавляем треугольник */
        right: 50px;
        top: -20px;
      }

      .q-btn {
        width: 110px;
        margin: 0 auto;
        padding: 6px 12px;
        color: #fff;
        border: none;
        border-radius: 4px;
      }
    }
  }

  &.white {
    color: white !important;

    .title {
      color: white !important;
    }

    span {
      color: white !important;
    }

    .username {
      color: white !important;
    }
  }
}

#sidemenu {
  display: none;

  .background-opacity {
    display: none;
  }
}

.mobile-header {
  display: none;
}

@media (max-width: 1129px) {
  .header {
    display: none;
  }
  .mobile-header {
    display: flex;
  }
  #sidemenu {
    display: block;
    //margin-left: 5px;
    nav {
      width: 300px;
      height: 100%;
      // height: calc(100% - #{$headerHeight} - #{$footerHeight});
      background: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      // box-shadow: 2px 0 3px$grey-6;
      overflow-y: auto;

      .sidemenu-header-main {
        display: flex;
      }

      .change-language-mobile-block {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 39px;
        left: 85%;

        &.change-language-mobile-block--right {
          left: auto;
          right: 85%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title-mobile-menu-block_logo {
        position: absolute;
        left: 24%;
        top: 34px;
        width: 160px;

        .mobile-menu-title {
          color: #3d424a;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
      }

      .title-mobile-menu-block_lang {
        position: absolute;
        left: 22px;
        top: 38px;
        //width: 175px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #3d424a;
        display: flex;

        .mobile-lang-back-button {
          rotate: -90deg;
          margin-right: 40px;

          img {
            width: 14.4px;
            height: 8.4px;
          }
        }
      }
    }

    .background-opacity {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(32, 48, 64, 0.75);
      display: block;
      z-index: 98;
    }

    .sidemenu {
      &__btn {
        display: block;
        width: 50px;
        height: 50px;
        background: inherit;
        border: none;
        position: relative;
        z-index: 10000;
        appearance: none;
        cursor: pointer;
        outline: none;

        span {
          display: block;
          background: #3d424a;
          width: 20px;
          height: 2px;
          margin: auto;
          position: absolute;
          top: 10px;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.4s ease;

          &.top {
            width: 10px;
            right: 10px;
            transform: translateY(-8px);
          }

          &.bottom {
            width: 10px;
            left: 10px;
            transform: translateY(8px);
          }
        }

        &.active {
          //.sidemenu__btn {
          span {
            background: #839aaf !important;
            //}
          }

          .top {
            width: 20px;
            right: 0;
            transform: rotate(-45deg);
          }

          .mid {
            transform: translateX(-20px) rotate(360deg);
            opacity: 0;
          }

          .bottom {
            width: 20px;
            left: 0;
            transform: rotate(45deg);
          }
        }

        &.white {
          span {
            background: #ffffff;
          }
        }
      }

      &__wrapper {
        padding-top: 104px;
      }

      &__list {
        list-style: none;
        padding: 0 0 0 16px;
        margin: 0;

        &.right-list {
          padding: 0 16px 0 0;
        }
      }

      &__item {
        a {
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #3d424a;
          display: block;
          transition: 0.4s ease;
          margin-bottom: 36px;

          &:hover {
            background: #ffffff;
            color: var(--var-blue-100);
          }
        }

        a.active {
          background: #ffffff;
          color: var(--var-blue-100);
        }

        .help-center-link {
          margin-bottom: 46px;
        }

        .sidemenu__list__language-menu__item {
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #3d424a;
          display: block;
          transition: 0.4s ease;
          margin-bottom: 36px;

          &:hover {
            background: #ffffff;
            color: var(--var-blue-100);
          }
        }

        .sidemenu__list__language-menu__item.active {
          background: #ffffff;
          color: var(--var-blue-100);
        }

        .sidemenu__item__button {
          .login-button-in-burger-menu {
            display: flex;
            justify-content: center;
            background: var(--var-blue-100);
            width: 92%;
            padding: 10px 32px 10px 24px;
            border-radius: 8px;

            &__icon {
              margin-right: 14.5px;
            }

            &__text {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #ffffff;
            }
          }
        }

        &__details {
          border: none;
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #3d424a;
          display: block;
          transition: 0.4s ease;
          margin-bottom: 36px;
          list-style: none;
          outline: none;
          user-select: none;

          summary {
            .summary-icon-close {
              display: block;
              position: relative;
              left: 85%;
              top: -8px;
              height: 9px;
              width: 15px;
            }

            .summary-icon-open {
              display: none;
              position: relative;
              left: 85%;
              top: -8px;
              height: 9px;
              width: 15px;
            }

            #side-menu-customers-title {
              color: #3d424a !important;
            }
          }

          a {
            margin: 28px 32px;
          }

          .for-designers {
            margin: 20px 32px 28px 32px;
          }
        }

        &__details[open] {
          summary {
            .summary-icon-close {
              display: none;
            }

            .summary-icon-open {
              display: block;
            }
          }
        }

        details summary::-webkit-details-marker {
          display: none;
        }

        details:focus,
        summary:focus {
          outline: 0;
          outline: none;
        }
      }
    }
  }
  .h-menu {
    display: none !important;
  }
  .h-wrp {
    justify-content: space-between;

    .right {
      margin-left: 0;

      .user-block {
        .username {
          display: none;
        }
      }
    }

    .logo {
      margin-right: 0 !important;
    }
  }

  .translateX-enter {
    transform: translateX(-200px);
    opacity: 0;
  }
  .translateX-enter-active,
  .translateX-leave-active {
    transform-origin: top left 0;
    transition: 0.2s ease;
  }
  .translateX-leave-to {
    transform: translateX(-200px);
    opacity: 0;
  }
}
</style>
