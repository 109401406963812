<template>
  <HeaderComponent />
  <main class="main-wrapper">
    <slot />
  </main>
  <FooterComponent />
  <ModulesUserLogin
    :show="showLoginModal"
    @update:show="
      $event ? user.showLoginModal() : user.hideLoginModal()
    "
    @hide="user.hideLoginModal"
  />
  <CookieModal />
  <LazyRussianTransferModalMobile
    v-if="showRussianTransferModalMobile"
    @close="showRussianTransferModalMobile = false"
  />
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref} from 'vue';
import {useRoute} from 'vue-router';
import {useI18n} from 'vue-i18n';
import CookieModal from '~/modules/Cookies/components/CookieModal.vue';
import {useUserStore} from '~/modules/User/user.store';
import {useLauncherInfoStore} from '~/store/launcher-info';
import {provide, useCookie} from '#imports';

const {locale} = useI18n();

const user = useUserStore();
const showLoginModal = computed(() => user.isShowLoginModal);
const userIsLogedIn = computed(() => user.isLoggedIn);
const userInfo = ref({username: ''});
const route = useRoute();

const showRussianTransferModalMobile = ref(false);

const runtimeConfig = useRuntimeConfig();

const launcherInfo = useLauncherInfoStore();

if (useCookie('df').value) {
  user.getProfile();
}

onBeforeMount(async () => {
  const f = async () => await launcherInfo.update();
  await f();
});

user.$subscribe((_, state) => {
  if (!state.error) {
    if (state.isLoggedIn) {
      user.hideLoginModal();
    }
    // user.isLoggedIn = true
    userInfo.value = state;
  }
});

function changeShowLoginModal(value: any) {
  if (value) {
    user.showLoginModal();
  } else {
    user.hideLoginModal();
  }
}

provide('changeShowLoginModal', changeShowLoginModal);

// Gorskih
// Убрать после выставки CityScape
watch(user.$state, (_) => {
  // const cookiePath = useCookie('path')
  // if (user.isLoggedIn && cookiePath.value) {
  //   useRouter().push(`/en/pay/${cookiePath.value}`)
  //   useCookie('path').value = null
  // }
  if (
    user.isLoggedIn &&
    route.path.includes('/plans') &&
    route.query.tariff
  ) {
    useRouter().push(`/en/pay/${route.query.tariff}`);
  }
  if (
    user.isLoggedIn &&
    route.query.sale &&
    route.path.includes('/plans') &&
    route.query.tariff
  ) {
    useRouter().push(
      `/en/pay/${route.query.tariff}?sale=${route.query.sale}`,
    );
  }
});

const userPlatform = () => {
  if (typeof navigator !== 'undefined') {
    return (
      /Android/.test(navigator.userAgent) ||
      /(iPhone|iPad|iPod)/.test(navigator.userAgent)
    );
  }
};

watch(
  () => locale.value,
  (newLocale) => {
    if (newLocale === 'ru' && userPlatform()) {
      showRussianTransferModalMobile.value = true;
    }
  },
);

onMounted(() => {
  if (route.query.sale && route.path.includes('/plans')) {
    user.showLoginModal();
  }
  if (route.query.tariff && route.path.includes('/plans')) {
    user.showLoginModal();
  }
});
</script>

<style lang="scss">
.cost {
  font-family: 'DreamFlat', sans-serif;
  font-size: 30px !important;
}
.cost-regular {
  font-family: 'DreamFlat', sans-serif;
  font-size: 18px !important;
}
.big-cost {
  font-family: 'DreamFlat', sans-serif;
  font-size: 48px !important;
}

.user-block {
  padding: 10px;
  padding-left: 0px;

  a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;

    .h-icon-pr {
      margin-right: 0;
    }
  }

  // .icon-profile {
  //   // background-color: red;
  // }

  .username {
    letter-spacing: 0.02em;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    color: #3d424a;
    width: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.logo {
  color: #3d424a;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.logo-right {
  margin-right: 0;
  margin-left: 52px;
}

.change-lang {
  margin-right: 0;
}

.for-whom {
  z-index: 1000;
  position: relative;

  &-main-button {
    button {
      border: none;
      background: inherit;
      cursor: pointer;
      color: $dark;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
    }

    .button_active {
      color: var(--var-blue-100);
    }

    .white {
      color: #ffffff;
    }
  }

  &-main-list {
    position: absolute;
    background-color: $primary;
    text-align: center;
    border-radius: 8px;
    width: 120px;
    padding: 4px;
    margin-top: 10px;
    top: 100%;
    right: 50%;
    transform: translateX(50%);

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $primary;
      top: 2px;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -10px);
    }

    &-element {
      width: 110px;
      margin: 0 auto;
      padding: 6px 0px;
      color: #fff;
      border: none;
      border-radius: 4px;
      text-transform: inherit;
      font-size: 14px;
      font-weight: 400;
    }

    &-element:hover {
      background: #4ea6ec;
      border-radius: 4px;
    }
  }
}

.goahead-item {
  position: relative;
  display: inline-block;

  .goahead-item-after-bottom {
    //content: "attr(data-content)";
    position: absolute;
    text-align: center;
    top: 44px;
    width: 100%;
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #839aaf;
  }
}

.list__wrp {
  color: #e1e5f0;
  .list-item__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
  }

  .list-item__text,
  .list-item__title {
    min-height: 32px;
  }
}

.header-gumb {
  display: none;
}

.h-btn {
  .q-btn {
    box-shadow: 0 3px 10px rgba(0, 180, 64, 0.5);
  }
}

.text-disable {
  color: #64686e;
}

details summary::-webkit-details-marker {
  display: none;
}

details > summary {
  list-style: none;
}

@media (max-width: $lg) {
  .header-gumb {
    display: block;

    .q-fab__actions {
      margin-left: 10px;
    }
  }

  .h-lang {
    display: none;
  }

  .h-btn {
    display: none;
  }

  .h-item {
    display: none;
  }

  .list__wrp {
    justify-content: space-between;

    .q-list {
      margin-right: 0;
    }
  }

  .list__item {
    margin-bottom: 30px;
    //width: 168px;
  }

  .hide {
    display: none;
  }
}

@media (max-width: $lg) {
  .right {
    .login.white {
      color: #ffffff;
    }
  }
}
</style>
