<template>
  <ui-footer v-if="!loc.includes('/user')" class="footer">
    <div class="footer__wrapper container">
      <div class="section-1">
        <ui-link to="/" class="logo">
          <DFLogo color="#fff" width="50" height="27" />
          <div>{{ $t(title) }}</div>
        </ui-link>
        <!--   TODO: удалить демо аскона     -->
        <ClientOnly>
          <ui-button
            v-show="!userPlatform"
            style="
              background: none;
              border: 1px solid white;
              color: white;
              max-width: 230px;
            "
            :color="'white'"
            :bacground="'none'"
            class="goahead-btn"
            @click="openIframe"
          >
            Магазин Аскона (демо)
          </ui-button>
        </ClientOnly>

        <div class="payments">
          <div class="">
            <SvgPaymentMaestroCard class="q-mr-sm" />
            <SvgPaymentMasterCard class="q-mr-sm" />
            <SvgPaymentVisa class="q-mr-sm" />
            <SvgPaymentPayPal class="q-mr-sm" v-if="!isDflat" />
            <SvgPaymentMir class="q-mr-sm" v-else />
          </div>
        </div>
        <div class="footer-buttons-wrap">
          <div class="footer-lang" v-if="listLang.length > 1">
            <ui-fab
              class="change-lang"
              :type="'light'"
              :themeDark="true"
              position="top"
              position-russian-tooltip="right"
              :options="listLang"
              @select="onSelectLang"
            >
              <IconLangWhite />
            </ui-fab>
            <div class="footer-lang-toolbar">
              {{ labelLang }}
            </div>
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="list-item__title">
          {{ $t('navigation') }}
        </div>
        <div class="list-item__text">
          <ui-link
            :class="{
              link_active: loc === `/${locale}`,
            }"
            class="link"
            to="/"
          >
            {{ $t('home') }}
          </ui-link>
        </div>
        <div class="list-item__text">
          <ui-link
            :class="{
              link_active: loc === `/${locale}/about`,
            }"
            to="/about"
            class="link"
          >
            {{ $t('aboutUs') }}
          </ui-link>
        </div>
        <div class="list-item__text">
          <span
            class="link"
            style="cursor: pointer"
            @click="isShowFeedBackModal = true"
          >
            {{ $t('cooperation') }}
          </span>
        </div>
        <div class="list-item__text">
          <span
            class="link"
            style="cursor: pointer"
            @click="isShowFeedBackModal = true"
          >
            {{ $t('support') }}
          </span>
        </div>
        <div v-if="!isDflat" class="list-item__text">
          <ui-link class="link" to="/blog">
            {{ $t('blogTitle') }}
          </ui-link>
        </div>
      </div>
      <div class="section-3">
        <div class="list-item__title">
          {{ $t('contacts') }}
        </div>
        <div v-if="!isDflat" class="list-item__text">
          {{ $t('DreamFlatFZLLC') }}
        </div>
        <div class="list-item__text">
          <a
            color="primary"
            href="mailto:support@dreamflat.com"
            class="link"
            itemprop="email"
          >
            support@dreamflat.com
          </a>
        </div>
        <div v-if="isDflat" class="list-item__text">
          {{ $t('inn') }} 7804658177
        </div>
        <!--        <div v-if="isDflat" class="list-item__text">-->
        <!--          <a-->
        <!--            class="link"-->
        <!--            color="primary"-->
        <!--            href="tel: +78123244522"-->
        <!--            itemprop="telephone"-->
        <!--          >-->
        <!--            +7 (812) 324-45-52-->
        <!--          </a>-->
        <!--        </div>-->
        <div
          v-if="isDflat"
          class="list-item__text"
          itemscope
          itemtype="http://schema.org/PostalAddress"
        >
          {{ $t('adresSpb') }}
        </div>
        <div class="links__social">
          <SocialMediaLinks
            :is-need-email="false"
            :isDflat="isDflat"
          />
        </div>
      </div>
      <div class="section-4">
        <div class="list-item__title">
          {{ $t('platforms') }}
        </div>
        <div class="list-item__text">Windows</div>
        <div class="list-item__text_disable">
          {{ $t('webVersionInDev') }}
        </div>
      </div>
    </div>
  </ui-footer>
  <div
    v-if="!loc.includes('/user')"
    class="under-footer__wrp q-py-lg"
  >
    <div
      class="container row wrap justify-between items-center content-start q-mt-xl gap-12"
    >
      <p v-if="isDflat" class="text-grey-1 q-ma-none">
        {{ $t('rightsReservedDflat', {endYear: getCurrentYear}) }}
      </p>
      <p v-if="!isDflat" class="text-grey-1 q-ma-none">
        {{ $t('rightsReserved', {endYear: getCurrentYear}) }}
      </p>
      <div class="row wrap justify-start items-start content-start">
        <ui-link to="/terms-of-use" class="link link_white q-mr-sm">
          {{ $t('termOfUse') }}
        </ui-link>
        <ui-link to="/privacy-policy" class="link link_white">
          {{ $t('privacyPolicy') }}
        </ui-link>
      </div>
    </div>
  </div>
  <lazy-feed-back-modal
    v-model:show="isShowFeedBackModal"
    v-if="isShowFeedBackModal"
  />
</template>
<script lang="ts" setup>
import {useRoute} from 'vue-router';
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRuntimeConfig} from '#imports';
import SocialMediaLinks from '~/components/SocialMediaLinks.vue';
import UiFab from '~/components/Ui/UiFab.vue';
import IconLangWhite from '~/assets/svg/lang-white.svg';
import UiFooter from '~/components/Ui/UiFooter.vue';
import UiLink from '~/components/Ui/UiLink.vue';
import DFLogo from '~/components/DFLogo.vue';
import DownloadLauncherButton from '~/components/DownloadLauncherButton.vue';
import {useBaseStore} from '~/modules/BaseModule/base.store';
import {LazyFeedBackModal} from '#components';
import {ArcWare} from '~/utils/PixelStreaming/ArcWare';

const router = useRoute();
const loc = computed(() => router.path);
const base = useBaseStore();
const listLang = base.listLangCurrent;
const userPlatform = ref(true);

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');
const title = runtimeConfig.public.vueAppTitle;

const isShowFeedBackModal = ref(false);

const getCurrentYear = ref(new Date().getFullYear());

const {locale} = useI18n();
const labelLang = computed(
  () => listLang.find((lang) => lang.value === locale.value)?.label,
);

const lang = ref();

function onSelectLang(option: string) {
  lang.value = option;
  locale.value = lang.value;
}

onMounted(() => {
  if (router.query.showFeedBack) {
    isShowFeedBackModal.value = true;
  }
});

const getUserPlatform = () => {
  if (typeof navigator !== 'undefined') {
    return (
      /Android/.test(navigator.userAgent) ||
      /(iPhone|iPad|iPod)/.test(navigator.userAgent)
    );
  }
  return false;
};

if (process.client) {
  userPlatform.value = getUserPlatform();
}

const openIframe = () => {
  const dfdArcware = new ArcWare();
  dfdArcware.init();
  dfdArcware.start();
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  @media (max-width: $md) {
    margin-top: $m-2;
  }
}

.text-disable {
  color: var(--var-white-000);
}

.payments {
  margin-top: $m-3;

  @media (max-width: $md) {
    margin-top: $m-1;
  }
}

.links__social {
  display: flex;
  flex-direction: column;
  margin-top: $m-2;
  gap: $g-3;

  &__email {
  }

  &__social {
  }
}

.footer {
  color: #fff;
  background: linear-gradient(
      0deg,
      rgba(18, 26, 52, 0.85) 0%,
      rgba(18, 26, 52, 0.85) 100%
    ),
    url($static-url + '/images/mp-main-bg-footer.webp'),
    lightgray 0px -608.357px / 100% 466.824% no-repeat;
  background-position: center;
  padding: $p-10 0;
  position: relative;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: $g-10;
    height: 100%;

    .section-1 {
      display: flex;
      flex-direction: column;
      gap: $g-5;

      .logo {
        justify-content: flex-start;
        color: #fff !important;
        padding: 0;

        .q-btn__content {
          display: flex;
          flex-direction: column;
        }
      }

      .footer-buttons-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .footer-lang {
          margin-top: -$mt-1;
          display: flex;
          flex-direction: row;
          gap: $g-3;
          margin-left: $m-1;
          justify-content: flex-start;
          align-items: center;
          width: 103px;

          .q-icon {
            font-size: 17px;
          }

          .footer-lang-toolbar {
            font-size: 15px;
            flex: none;
          }
        }
      }
    }

    .section-2 {
      display: flex;
      flex-direction: column;
      gap: $g-3;
    }

    .section-3 {
      display: flex;
      flex-direction: column;
      gap: $g-3;
    }

    .section-4 {
      display: flex;
      flex-direction: column;
      gap: $g-3;
    }

    @media (max-width: $lg) {
      grid-template-columns: 1fr 1fr;
      row-gap: $g-8;
      padding: $p-0 $p-10;
      .section-1 {
        .logo {
          justify-content: flex-start;
        }

        .footer-buttons-wrap {
          margin-top: $m-2;
          align-items: flex-start;

          .footer-lang {
            margin-top: $m-0;
          }

          .under-button-text {
            bottom: $m-11;
          }
        }
      }
    }
    @media (max-width: $md) {
      padding: $p-0 $p-5;
      grid-template-columns: 1fr;
    }
  }

  .footer-list-info {
    margin-right: 24px;
    width: 100%;
  }

  .right {
    .h-lang {
      .q-icon {
        font-size: 18px;
      }
    }

    .lang-btn-groupe-f {
      width: 120px;
      background: var(--var-blue-100);
      border-radius: 8px;
      position: relative;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        /* Прозрачные границы */
        border-bottom: 10px solid #2491e7;
        /* Добавляем треугольник */
        right: 50px;
        top: -20px;
      }

      .q-btn {
        width: 110px;
        margin: 0 auto;
        padding: 6px 12px;
        color: #fff;
        border: none;
        border-radius: 4px;
      }
    }
  }
}

.list-item {
  &__title {
    @include launcher-h1;
  }

  &__text {
    @include launcher-p3;

    &_disable {
      color: var(--var-white-000-40);
    }
  }
}

.under-footer__wrp {
  background-color: var(--var-black-000);
  padding: $p-3;
}

.link_active {
  font-weight: 700;
}
</style>
