import type { IncomingEventsArcware } from "../types";

var functions = {
  OpenURL: (url: string) => {
    const a_html = document.createElement("a");
    a_html.href = url;
    a_html.target = "_blank";
    a_html.click();
    return 1;
  },
  exit: () => {
    return -1;
  },
};

export function SystemEvents(data: IncomingEventsArcware) {
  console.debug(data.event);
  return functions[data.event](data.data);
}
