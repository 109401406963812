import {
  ArcwareApplication,
  ArcwareConfig,
  ArcwareInit,
  ArcwarePixelStreaming,
  type ErrorMessage,
  type LoveLetter,
  type Queue,
} from '@arcware-cloud/pixelstreaming-websdk';
import type {
  HandlerEventArcware,
  IncomingEventsArcware,
} from './types';
import {trimJsonFromDFD} from './helper';
import {SystemEvents} from './services/system';

export class ArcWare {
  private videoContainer: HTMLElement | undefined;
  private config: ArcwareConfig | undefined;
  private pixelStreaming: ArcwarePixelStreaming | undefined;
  private aplication: ArcwareApplication | undefined;
  private services = {
    system: SystemEvents,
  };

  constructor() {
  }

  private getApplicationResponse(res: string) {
    const data: IncomingEventsArcware = JSON.parse(
      trimJsonFromDFD(res),
    );
    const responseFunction = this.services[data.service](data);

    if (responseFunction === -1) {
      this.exit();
    }
  }

  private loveLetterHandler(message: LoveLetter) {
    // console.debug(message);
  }

  private errorHandler(message: ErrorMessage) {
    // console.debug(message);
  }

  private sessionIdHandler(id: string) {
    // console.debug(id);
  }

  private queueHandler(message: Queue) {
    // console.debug(message);
  }

  private exit (){
    setTimeout(() => {
      this.videoContainer?.parentNode?.removeChild(
        this.videoContainer!,
      );
    }, 1);
    document.body.style.overflowY = 'scroll';
  }

  public init() {
    const arcwareConfig = ArcwareInit(
      {
        // Replace with your actual share ID
        shareId: 'share-90573c11-1abc-4928-968b-3798c1f671fb',
        // shareId: "share-d2477d89-0548-4785-9b9f-149071fb4fe3",
      },
      {
        initialSettings: {
          StartVideoMuted: true,
          AutoConnect: true,
          AutoPlayVideo: true,
          TouchInput: true,
          FakeMouseWithTouches: true,
          ControlsQuality: true,
        },
        settings: {
          fullscreenButton: true,
          queueHandler: this.queueHandler,
          sessionIdHandler: this.sessionIdHandler,
          errorHandler: this.errorHandler,
          loveLetterHandler: this.loveLetterHandler,
          loveLetterLogging: true
        },
      },
    );
    this.aplication = arcwareConfig.Application;
    this.pixelStreaming = arcwareConfig.PixelStreaming;
    this.config = arcwareConfig.Config;
    this.aplication.getApplicationResponse(
      this.getApplicationResponse.bind(this),
    );
  }

  public start() {
    if (this.aplication) {
      this.videoContainer = document.createElement('div');
      this.videoContainer.style.zIndex = '999999';
      this.videoContainer.style.position = 'fixed';
      this.videoContainer.style.top = '0';
      this.videoContainer.style.left = '0';
      this.videoContainer.style.height = '100vh';
      this.videoContainer.style.width = '100vw';
      document.body.style.overflowY = 'hidden';
      document.body.appendChild(this.videoContainer);
      this.videoContainer.requestFullscreen();
      this.videoContainer.appendChild(this.aplication.rootElement);
    }
  }

  public handleCommand(desc: HandlerEventArcware) {
    if (this.aplication) {
      this.aplication.emitUIInteraction(desc);
    }
  }
}
